const userDB = {

  companyTypes: [
    {
      value: 'startup',
      text: 'Startup',
    },
    {
      value: 'enterprise',
      text: 'Enterprise',
    },
    {
      value: 'agency',
      text: 'Agency',
    },
    {
      value: 'smb',
      text: 'SMB',
    },
  ],
  departments: [
    {
      value: 'business',
      text: 'Business',
    },
    {
      value: 'marketing',
      text: 'Marketing',
    },
    {
      value: 'product',
      text: 'Product',
    },
    {
      value: 'development',
      text: 'Development',
    },
    {
      value: 'sales',
      text: 'Sales',
    },
    {
      value: 'support_success',
      text: 'Customer Support/Success',
    },
  ],
  positions: [
    {
      value: 'founder',
      text: 'Founder',
    },
    {
      value: 'c-level',
      text: 'C-Level',
    },
    {
      value: 'manager',
      text: 'Manager',
    },
    {
      value: 'staff',
      text: 'Staff',
    },
    {
      value: 'student',
      text: 'Student',
    },
    {
      value: 'freelance',
      text: 'Freelancer',
    },
  ],
}

export default userDB
