// axios
import axios from 'axios'

const trackingService = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_TRACKING_URL,
  timeout: 10000,

  // headers: {'X-Custom-Header': 'foobar'}
})

trackingService.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = process.env.VUE_APP_TRACKING_TOKEN

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

trackingService.interceptors.response.use(response => response, error => Promise.reject(error))

// Vue.prototype.$http = trackingService

export default trackingService
