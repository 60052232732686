const user = [
  {
    path: '/user/settings',
    name: 'user-settings',
    component: () => import('@/views/user/Settings.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
  },
  {
    path: '/user/twitter-auth',
    name: 'user-twitter-auth',
    component: () => import('@/views/user/TwitterAuth.vue'),
    meta: {
      layout: 'content',
    },
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
  },
]

export default user
