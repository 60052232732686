import apiClient from '@/services'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import auth from './auth'
import social from './social'
import tools from './tools'
import tracking from './tracking'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    unauthenticated: false,
    notification: {
      show: false,
      text: null,
    },

    limits: {
      loaded: false,

      tweetShots: {
        max: 0,
        used: 0,
        left: 0,
        resetDays: 30,
      },

      pageShots: {
        max: 0,
        used: 0,
        left: 0,
        resetDays: 30,
      },

    },
  },
  mutations: {},
  actions: {
    notify({ state }, message) {
      state.notification.text = message
      state.notification.show = true
    },

    async fetchUsageLimits({ state }) {
      if (!state.limits.loaded) {
        try {
          state.loading = true

          const response = await apiClient.fetchUsageLimits()

          const limits = {
            loaded: true,

            tweetShots: {
              max: response.data.limits.twitter_max_images,
              used: response.data.usage.twitter_used_images,
              left: response.data.limits.twitter_max_images - response.data.usage.twitter_used_images,
              resetDays: response.data.limits.resetDays,
            },
            pageShots: {
              max: response.data.limits.diff_max_pages,
              used: response.data.usage.diff_used_pages,
              left: response.data.limits.diff_max_pages - response.data.usage.diff_used_pages,
              resetDays: response.data.limits.resetDays,
            },
          }
          state.limits = limits

          state.loading = false
        } catch (error) {
          console.log(error)
        }
      }
    },

    async fetchUsageLimitsForce({ state }) {
      state.limits.loaded = false
      await this.dispatch('fetchUsageLimits')
    },

  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    social,
    auth,
    tracking,
    tools,
  },
})
