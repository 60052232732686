import { mdiFormatQuoteOpen, mdiMonitorDashboard, mdiTwitter } from '@mdi/js'

export default [
  {
    title: 'Tools',
    icon: mdiMonitorDashboard,
    children: [
      // {
      //   icon: mdiMonitorDashboard,
      //   title: 'Page Analyser',
      //   to: 'page-diff',
      // },
      {
        icon: mdiTwitter,
        svg: require('@/assets/images/svg/twitter-icon.svg'),
        title: 'TweetShots',
        to: 'twitter-images',
        href: 'https://divbyzero.com/tools/tweetshots',
      },

      {
        icon: mdiTwitter,
        svg: require('@/assets/images/browsershots/browsershot-icon.svg'),
        title: 'BrowserShots',
        to: 'browsershots',
      },

      {
        icon: mdiFormatQuoteOpen,
        svg: require('@/assets/images/svg/quote-icon.svg'),
        title: 'Quotes Shots',
        to: 'quotes',
      },
      {
        icon: mdiFormatQuoteOpen,
        svg: require('@/assets/images/svg/zeroblur-icon.svg'),
        title: 'ZeroBlur',
        to: 'blur-extention',
      },
      {
        icon: mdiFormatQuoteOpen,
        svg: require('@/assets/images/svg/saas-icon.svg'),
        title: 'SaaS Examples',
        to: 'sass-examples',
        href: 'https://divbyzero.com/tools/saas/',
      },
    ],
  },
]
