/* eslint-disable import/no-cycle */
import browsershots from './browsershots'
import emailSpy from './emailSpy'
import pageDiff from './page-diff'

export default {
  namespaced: true,

  modules: { browsershots, pageDiff, emailSpy },
}
