<template>
  <router-link
    to="/"
    class=" d-flex align-center justify-center mb-6"
  >
    <v-img
      :src="appLogo"
      max-height="250px"
      max-width="250px"
      alt="logo"
      contain
    ></v-img>
  </router-link>
</template>

<script>
import themeConfig from '@themeConfig'

export default {
  data() {
    return {
      appLogo: themeConfig.app.logo,
      appName: themeConfig.app.name,
    }
  },
}
</script>
