<template>
  <v-dialog
    v-model="isVisible"
    width="800"
    persistent
  >
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Account Details</span>
              <span class="text--secondary text-xs">Setup your account details</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step
          :complete="activeStep > 2"
          step="2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Personal Info</span>
              <span class="text--secondary text-xs">Setup Information</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>
      </v-stepper-header>

      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <v-card
            class="mb-8"
          >
            <v-card-text>
              <v-alert
                v-if="requestError"
                color="error"
                text
                dark
                class="my-4"
              >
                {{ requestError }}
              </v-alert>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="user.website"
                    outlined
                    :error-messages="errorMessages.website"
                    label="Website"
                    placeholder="website"
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="user.country_code"
                    :items="countriesFull"
                    item-text="name"
                    item-value="code"
                    label="Country"
                    outlined
                    class="mb-6"
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between">
            <v-btn
              :loading="loading"
              outlined
              @click="onSkip()"
            >
              Skip
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              @click="onSubmit"
            >
              Next
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2">
          <v-card
            class="mb-8"
          >
            <v-card-text class="mt-5">
              <v-form
                ref="updateForm"
                class="multi-col-validation"
              >
                <v-alert
                  v-if="requestError"
                  color="error"
                  text
                  dark
                  class="my-4"
                >
                  {{ requestError }}
                </v-alert>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="user.company_name"
                      outlined
                      label="Company Name"
                      placeholder="Company Name"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="user.company_type"
                      :items="data.companyTypes"
                      label="Company Type"
                      outlined
                      class="mb-6"
                      hide-details="auto"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="user.department"
                      :items="data.departments"
                      label="Your area of focus"
                      outlined
                      class="mb-6"
                      hide-details="auto"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="user.position"
                      :items="data.positions"
                      label="Position"
                      outlined
                      class="mb-6"
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between">
            <v-btn
              outlined
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              @click="onSkip"
            >
              Complete
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import apiClient from '@/services'

import countriesFull from '@/@fake-db/data/other/countriesFull'
import userDB from '@/@fake-db/data/userDB'

export default {
  setup(props, { emit, root: { $store } }) {
    const userData = computed(() => $store.state.auth.user)
    const user = ref({
      name: userData.value.name,
      email: userData.value.email,
      website: userData.value.website,
      position: userData.value.position,
      company_type: userData.value.company_type,
      department: userData.value.department,
      company_name: userData.value.company_name,
      country_code: userData.value.country_code,
    })

    const isVisible = ref(true)
    const skip = ref(false)
    const activeStep = ref(1)
    const loading = ref(false)
    const errorMessages = ref([])
    const requestError = ref(null)

    const onSubmit = () => {
      requestError.value = null
      loading.value = true
      console.log(user.value.website)
      apiClient
        .updateUser({ ...user.value, onboarded: 1 })
        .then(response => {
          $store.dispatch('auth/setUserData', response.data.data)
          if (skip.value === true) {
            $store.dispatch('auth/HndleOnboardedDialog', false)
            isVisible.value = false
          }
          if (activeStep.value < 2) {
            activeStep.value += 1
          }
        })
        .catch(err => {
          requestError.value = err.response.data.error || err.response.data.message
        })
        .finally(() => {
          loading.value = false
        })
    }
    const onSkip = () => {
      skip.value = true
      onSubmit()
    }

    return {
      user,
      data: userDB,

      isVisible,
      activeStep,
      loading,
      countriesFull,

      requestError,
      errorMessages,
      onSubmit,
      onSkip,
    }
  },
}
</script>
