const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null

export default {
  namespaced: true,
  state: {
    isLoggedIn: !!userData,
    user: userData,
    onboardedDialog: false,
  },
  getters: {},
  mutations: {},
  actions: {
    // after login set user data
    setUserData({ state, dispatch }, payload) {
      state.isLoggedIn = true
      state.user = payload
      localStorage.setItem('userData', JSON.stringify(payload))
      dispatch('tracking/contacts', {}, { root: true })
      console.log('setting up limits')
      dispatch('social/fetchUsageLimits', {}, { root: true })
    },

    // after logout
    unsetUserData({ state }) {
      document.cookie = `attkn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.VUE_APP_DOMAIN}`
      document.cookie = `dvbzsslg=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${process.env.VUE_APP_DOMAIN}`

      this._vm.$cookie.delete('attkn')
      this._vm.$cookie.delete('dvbzsslg')

      state.isLoggedIn = false
      state.user = null
      localStorage.removeItem('userData')
    },

    HndleOnboardedDialog({ state }, value) {
      state.onboardedDialog = value
    },
  },
}
