export default {
  SET_LIMITS(state, limits) {
    state.limits = limits
  },
  SET_MY_BACKGROUNDS(state, payload) {
    state.myBackgrounds = payload.map(item => {
      item.background_path = process.env.VUE_APP_IMAGE_URL + item.background_path

      return item
    })
  },
}
