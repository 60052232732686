const dashboard = [
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
    component: () => import('@/views/dashboards/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard
