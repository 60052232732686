export default {
  namespaced: true,
  state: {
    loading: false,
    image: {
      ready: false,
      path: 'null',
    },
    settings: {
      browser: 'simple-browser',
      theme: 'light',
      background: '#fff', // not managed
      padding: {
        horizontal: 100, // px
        vertical: 150,
      },
      themeSwitch: true,
      title: {
        enabled: false,
        value: null,
      },
      url: {
        enabled: false,
        value: null,
      },
      shadow: {
        enabled: false,
        intensity: 5,
        value: [1, 1.2],
      },
      effects: {
        intensity: 800,
        x: 0,
        y: 0,
        z: 0,
      },
    },
  },
  actions: {
    toggleTheme({ state }) {
      state.settings.theme = state.settings.theme === 'light' ? 'dark' : 'light'
    },
    toggleURL({ state }) {
      state.settings.url.enabled = !state.settings.url.enabled
    },
    clearImage({ state }) {
      state.image.ready = false
    },
    updateBrowser({ state }, browser) {
      state.settings.browser = browser.id
      state.settings.url.value = browser.url.value
      state.settings.title = browser.title
      state.settings.theme = browser.theme.value
      state.settings.themeSwitch = browser.theme.switch
    },

    toggleShadow({ state }) {
      state.settings.shadow.enabled = !state.settings.shadow.enabled
    },

    resetEffects({ state }) {
      state.settings.effects = {
        intensity: 800,
        x: 0,
        y: 0,
        z: 0,
      }
    },
  },
  mutations: {
    UPDATE_PADDING(state, payload) {
      state.settings.padding = payload
    },

    UPDATE_EFFECTS(state, payload) {
      state.settings.effects[payload.key] = payload.value
    },

    IMAGE_READY(state, payload) {
      state.image = {
        ready: true,
        path: payload,
      }
    },

    UPDATE_SHADOW_INTENSITY(state, payload) {
      state.settings.shadow.intensity = payload
    },
  },

}
