import axiosClient from '@/plugins/axios'

export default {
  addPageRequest(payload) {
    return axiosClient.post('diff/page/create', payload)
  },
  updatePageRequest(payload) {
    return axiosClient.put(`diff/page/update/${payload.id}`, payload)
  },
  fetchPages() {
    return axiosClient.get('diff/page/all')
  },
  deletePage(id) {
    return axiosClient.delete(`diff/page/delete/${id}`)
  },
  pausePage(payload) {
    return axiosClient.post('diff/page/pause', payload)
  },
  fetchActivites(page) {
    return axiosClient.post('diff/fetch-activites', page)
  },
  fetchDiffVersion(diffId) {
    return axiosClient.get(`diff/fetch-diffversion/${diffId}`)
  },
  pageVersionStats(pageId) {
    return axiosClient.get(`diff/page-version-stats/${pageId}`)
  },

  // spy emails
  fetchSpyEmails() {
    return axiosClient.get('spy-email')
  },
  fetchSpyEmailVersions(payload) {
    return axiosClient.post('spy-email/versions', payload)
  },
  storeSpyEmail(payload) {
    return axiosClient.post('spy-email/store', payload)
  },
  updateSpyEmail(payload) {
    return axiosClient.put(`spy-email/update/${payload.id}`, payload)
  },
  deleteSpyEmail(payload) {
    return axiosClient.delete(`spy-email/delete/${payload}`)
  },
  starredSpyEmail(versionId) {
    return axiosClient.post(`spy-email/starred/${versionId}`)
  },

  // common
  toolActivity: payload => axiosClient.post('tool-activity/store', payload),

}
