const tools = [
  {
    path: '/tools/tweetshots',
    name: 'twitter-images',
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
    component: () => import('@/views/social/TwitterImages.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/tools/quoteshots',
    name: 'quotes',
    component: () => import('@/views/social/Quotes.vue'),
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tools/page-diff',
    name: 'page-diff',
    component: () => import('@/views/page-diff/Index.vue'),
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tools/page-diff/:diffId',
    props: true,
    name: 'compare-dialog-only',
    component: () => import('@/views/page-diff/CompareDialogWrapper.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tools/blur-extention',
    name: 'blur-extention',
    component: () => import('@/views/tools/blur-extention/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tools/sass-examples',
    name: 'sass-examples',
    component: () => import('@/views/tools/blur-extention/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tools/browsershots',
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
    name: 'browsershots',
    component: () => import('@/views/tools/browsershots/Index.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/tools/email-spy',
    beforeEnter: () => {
      window.location.href = 'https://divbyzero.com/tools/tweetshots/'
    },
    name: 'emailSpy',
    component: () => import('@/views/tools/email-spy/Index.vue'),
    meta: {
      layout: 'content',
    },
  },

]

export default tools
