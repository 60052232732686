import trackingService from '@/plugins/trackingService'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,

  actions: {
    contacts() {
      const { user } = this.state.auth

      const nameArr = user.name.split(' ')
      const firstName = nameArr[0]
      nameArr.splice(0, 1)

      const payload = {
        contactId: `${user.id}`,
        firstName,

        email: user.email,
        properties: {
          company_name: user.company_name,
          company_type: user.company_type,
          country_code: user.country_code,
          department: user.department,
          website: user.website,
        },
      }

      if (nameArr.length) {
        payload.lastName = nameArr.join(' ')
      }

      trackingService.post('contacts', payload)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })
    },

    logActivity({ state }, payload) {
      const { user } = this.state.auth

      trackingService.post(`contacts/${user.id}/activities`, payload)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
