import axiosClient from '@/plugins/axios'

export default {
  getUser() {
    return axiosClient.get('auth/user')
  },
  changeAvatar(payload) {
    return axiosClient.post('auth/changeAvatar', payload)
  },

  updateUser(payload) {
    return axiosClient.post('auth/updateUser', payload)
  },
  updatePassword(payload) {
    return axiosClient.post('auth/changePassword', payload)
  },
  getReferredUsers() {
    return axiosClient.get('referral/getReferredUsers')
  },
}
