// axios
import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

Vue.use(store)

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API,

  // timeout: 10000,

  // headers: {
  //   'Content-Type': 'application/json',
  //   accept: 'application/json',
  // },

  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // console.log(accessToken)
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const ignoredPaths = [
      'auth/login',
      'auth/login?v=1',
      'auth/resetPassword',
      'auth/sendResetLink',
      'auth/register?v=1',
      'auth/logout',
      'auth/user?ignore=1',
    ]
    const path = error.config.url
    if (error.response.status === 401 && !ignoredPaths.includes(path)) {
      store.state.unauthenticated = true
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
