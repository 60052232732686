<template>
  <v-dialog
    v-model="isVisible"
    width="600"
    persistent
  >
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="mx-auto"
          >
            <v-card flat>
              <v-card-text>
                <!-- brand logo -->
                <app-logo></app-logo>
                <!--/ brand logo -->
              </v-card-text>

              <v-card-text>
                <p class="text-2xl font-weight-semibold text--primary mb-2">
                  Session has expired
                </p>
                <p class="mb-2">
                  Please re-enter your password to continue the adventure
                </p>
              </v-card-text>

              <!-- login form -->
              <v-card-text>
                <v-form
                  ref="loginForm"
                  @submit.prevent="handleFormSubmit"
                >
                  <v-alert
                    v-if="requestError"
                    color="error"
                    text
                    dark
                    class="my-4"
                  >
                    {{ requestError }}
                  </v-alert>

                  <flash-message></flash-message>

                  <v-text-field
                    v-model="email"
                    outlined
                    label="Email"
                    placeholder="email"
                    :error-messages="errorMessages.email"
                    :rules="[validators.required, validators.emailValidator]"
                    hide-details="auto"
                    class="mb-6"
                    autocomplete="off"
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    outlined
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Password"
                    :error-messages="errorMessages.password"
                    placeholder="Password"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :rules="[validators.required]"
                    hide-details="auto"
                    class="mb-2"
                    autocomplete="off"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>

                  <div class="d-flex align-center justify-space-between flex-wrap">
                    <v-checkbox
                      hide-details
                      label="Remember Me"
                      class="mt-0"
                    >
                    </v-checkbox>

                    <!-- forget link -->
                    <router-link
                      :to="{name:'auth-forgot-password'}"
                      class="ms-3"
                    >
                      Forgot Password?
                    </router-link>
                  </div>

                  <v-btn
                    block
                    color="primary"
                    type="submit"
                    class="mt-6"
                  >
                    Login
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import themeConfig from '@themeConfig'
import AppLogo from '@/views/components/app/AppLogo.vue'

export default {
  components: {
    AppLogo,
  },
  created() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userAbility')
  },
  setup() {
    const isVisible = ref(true)

    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy

    // const isDialogVisible = ref(true)

    const isPasswordVisible = ref(false)

    const email = ref('')
    const localUserData = JSON.parse(localStorage.getItem('userData'))
    if (localUserData) {
      email.value = localUserData.email
    }

    const password = ref('')
    const errorMessages = ref([])
    const requestError = ref('')
    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      axios
        .post('auth/login?v=1', { email: email.value, password: password.value })
        .then(response => {
          const { accessToken } = response.data

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          return response
        })
        .then(res => {
          vm.$cookie.set('dvbzsslg', 1, { domain: process.env.VUE_APP_DOMAIN })
          vm.$cookie.set('attkn', res.data.accessToken, { domain: process.env.VUE_APP_DOMAIN })

          axios.get('auth/user').then(response => {
            const user = response.data

            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.ability

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // Set user's data in store auth
            vm.$store.dispatch('auth/setUserData', user)

            // On success redirect to home
            window.location.reload()
          })
        })
        .catch(error => {
          console.log(error)

          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response)
          requestError.value = error.response.data.message
        })
    }

    return {
      isVisible,
      handleFormSubmit,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
      requestError,
    }
  },
}
</script>
