<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.profile_photo_path"
            :src="__serverImage(userData.profile_photo_path)"
          ></v-img>
          <span
            v-else
            class="font-weight-semibold "
          >{{ avatarText(userData.name) }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.profile_photo_path"
              :src="__serverImage(userData.profile_photo_path)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold "
            >{{ avatarText(userData.name) }}</span>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userData.name || userData.email }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'user-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import axios from '@axios'
import { useRouter } from '@core/utils'
import { getCurrentInstance, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/plugins/acl/config'

export default {
  setup(props, { root: { $store } }) {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = computed(() => $store.state.auth.user)

    const logoutUser = () => {
      axios.get('auth/logout').finally(() => {
        // console.log(localStorage.getItem('accessToken'))

        // Remove userData from localStorage
        // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        // Reset ability
        vm.$ability.update(initialAbility)

        // Un set user's data in store auth
        vm.$store.dispatch('auth/unsetUserData')

        // Redirect to login page
        router.push({ name: 'auth-login' })
      })
    }

    return {
      logoutUser,
      userData,

      avatarText,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
