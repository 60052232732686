import axiosClient from '@/plugins/axios'

export default {
  getChangeLog() {
    return axiosClient.get('general/getChangelog')
  },

  getUpcoming() {
    return axiosClient.get('general/getUpcoming')
  },

  toggleUpcomingVote(payload) {
    return axiosClient.post('general/toggleUpcomingVote', payload)
  },

  saveUserUpcoming(payload) {
    return axiosClient.post('general/saveUserUpcoming', payload)
  },
}
