// eslint-disable-next-line import/no-cycle
import apiClient from '@/services'

export default {
  namespaced: true,
  state: {

    loading: {
      default: false,
      timeline: false,
    },

    dialogs: {
      create: false,
      edit: false,
      delete: false,
    },

    // timeline
    search: {
      enabled: false,
      keywords: null,
    },

    monitoriedEmail: null,

    starredMails: false,
    emails: null,
    versions: null,
    activeEmail: null,
  },

  actions: {
    // dialogs trigger
    createDialogTrigger({ state }, payload = null) {
      if (typeof (payload) === 'boolean') state.dialogs.create = payload
      state.dialogs.create = !state.dialogs.create
      state.monitoriedEmail = null
    },

    // new email created callaback
    setMonitoredEmail({ state }, payload) {
      state.monitoriedEmail = payload
    },

    editDialogTrigger({ state }, payload = null) {
      if (typeof (payload) === 'boolean') state.dialogs.edit = payload
      state.dialogs.edit = !state.dialogs.edit
    },

    // fetch emails
    async fetchEmails({ state }) {
      state.loading.default = true
      apiClient.fetchSpyEmails()
        .then(response => {
          state.emails = response.data.emails
        }).finally(() => {
          state.loading.default = false
        })
    },

    async fetchVersions({ state }) {
      state.loading.timeline = true
      const params = { email_id: null, keywords: null, starred_only: null }
      if (state.search.enabled && state.search.keywords) { params.keywords = state.search.keywords }

      if (state.activeEmail) params.email_id = state.activeEmail.id
      if (state.starredMails) params.starred_only = true

      await apiClient.fetchSpyEmailVersions(params)
        .then(response => {
          state.versions = response.data
        }).finally(() => {
          state.loading.timeline = false
        })
    },

    async fetchMoreVersions({ state }) {
      let currentPage = state.versions.current_page
      currentPage += 1

      const params = {
        email_id: null, page: currentPage, keywords: null, starred_only: null,
      }
      if (state.search.enabled && state.search.keywords) { params.keywords = state.search.keywords }
      if (state.activeEmail) params.email_id = state.activeEmail.id
      if (state.starredMails) params.starred_only = true

      state.loading.timeline = true
      await apiClient.fetchSpyEmailVersions(params)
        .then(response => {
          const oldData = state.versions.data
          state.versions = response.data
          state.versions.data = oldData.concat(response.data.data)
        }).finally(() => {
          state.loading.timeline = false
        })
    },

    // set active email
    setActiveEmail({ state, dispatch }, payload) {
      state.starredMails = false
      state.activeEmail = payload
      dispatch('fetchVersions')
    },

    // fetch starred versions
    fetchStarredVersions({ state, dispatch }) {
      state.activeEmail = null
      state.starredMails = true
      dispatch('fetchVersions')
    },

    // delete email
    deleteEmail({ state, dispatch }, email) {
      state.loading.default = true
      apiClient
        .deleteSpyEmail(email.id)
        .then(response => {
          dispatch('fetchEmails')
          dispatch('fetchVersions')
          dispatch('notify', response.data.message, { root: true })
          if (state.activeEmail && state.activeEmail.id === email.id) state.activeEmail = null
        })
        .catch(err => {
          dispatch('notify', err.response.data.message || err.response.data.error, { root: true })
        }).finally(() => {
          state.loading.default = false
        })
    },

    // enable /disable starred versions
    starredSpyEmail({ state, dispatch }, version) {
      state.loading.timeline = true
      apiClient
        .starredSpyEmail(version.id)
        .then(response => {
          dispatch('fetchStarredVersions')
          dispatch('notify', response.data.message, { root: true })
        })
        .catch(err => {
          dispatch('notify', err.response.data.message || err.response.data.error, { root: true })
        }).finally(() => {
          state.loading.timeline = false
        })
    },

  },
}
