import auth from './auth'
import general from './general'
import social from './social'
import tools from './tools'

export default {
  ...social,
  ...auth,
  ...tools,
  ...general,
}
