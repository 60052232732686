// eslint-disable-next-line import/no-cycle
import apiClient from '@/services'

export default {
  namespaced: true,
  state: {
    loading: {
      default: false,
      pages: false,
      activityTimeline: false,
      activityTimelineMore: false,
    },
    jobDialog: false,
    pages: null,
    activePage: null,
    activities: null,
  },
  actions: {
    deletePage({ state, dispatch }, page) {
      state.loading.default = true
      apiClient
        .deletePage(page.id)
        .then(response => {
          dispatch('fetchPages')
          dispatch('notify', response.data.message, { root: true })
          dispatch('fetchUsageLimitsForce', {}, { root: true })
          if (state.activePage && state.activePage.id === page.id) state.activePage = null
        })
        .catch(err => {
          dispatch('notify', err.response.data.message || err.response.data.error, { root: true })
        }).finally(() => {
          state.loading.default = false
        })
    },

    jobDialogToggle({ state }, payload = null) {
      if (typeof (payload) === 'boolean') state.jobDialog = payload
      state.jobDialog = !state.jobDialog
    },

    // render activities for seleted page
    setActivePage({ state, dispatch }, page) {
      state.activePage = page
      dispatch('fetchActivites')
    },

    updatePageSettings({ state }, payload) {
      state.loading.default = true
      state.activePage.settings = payload
      apiClient.updatePageSettings({
        settings: payload,
        id: state.activePage.id,
      }).then(() => {
      }).finally(() => {
        state.loading.default = false
      })
    },

    /**
     * fetch pages
     */

    async fetchPages({ state }) {
      state.loading.pages = true
      apiClient
        .fetchPages()
        .then(response => {
          state.pages = response.data.pages
        })
        .finally(() => {
          state.loading.pages = false
        })
    },

    /**
     * fetch activies
     */
    fetchActivites({ state }) {
      state.loading.activityTimeline = true
      const params = { diff_page_id: null, page: 1 }
      if (state.activePage) params.diff_page_id = state.activePage.id
      apiClient
        .fetchActivites(params)
        .then(response => {
          state.activities = response.data
        })
        .finally(() => {
          state.loading.activityTimeline = false
        })
    },

    fetchMoreActivites({ state }) {
      state.loading.activityTimelineMore = true
      let currentPage = state.activities.current_page
      currentPage += 1

      const params = { diff_page_id: null, page: currentPage }
      if (state.activePage) params.diff_page_id = state.activePage.id

      apiClient
        .fetchActivites(params)
        .then(response => {
          const oldData = state.activities.data
          state.activities = response.data
          state.activities.data = oldData.concat(response.data.data)
        })
        .finally(() => {
          state.loading.activityTimelineMore = false
        })
    },

  },
}
