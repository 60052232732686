import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/social-fonts.css'
import '@/styles/styles.scss'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import moment from 'moment'
import Vue from 'vue'
import VueFlashMessage from 'vue-flash-message'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
import App from './App.vue'
import './plugins/acl'
import './plugins/mixin'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

const VueCookie = require('vue-cookie')

require('vue-flash-message/dist/vue-flash-message.min.css')

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

Vue.use(VueLazyload)

// or with options
const loadimage = require('./assets/images/svg/image-loader.svg')

Vue.use(VueLazyload, {
  preLoad: 1.3,

  // error: errorimage,
  loading: loadimage,
  attempt: 1,
})

Vue.use(VueFlashMessage)
Vue.use(CKEditor)
Vue.use(VueCookie)

Vue.prototype.moment = moment
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
