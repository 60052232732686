import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    colorScheme: {
      //  backgroundImage type : raw
      backgroundImage: 'background:linear-gradient(150deg, rgb(95, 108, 138), rgb(48, 59, 94), rgb(14, 18, 38))',
      backgroundColor: '#000',
      cardBackgroundColor: '#FFF',
      cardBackgroundImage: null,
      textPrimary: '#000',
      textSecondary: '#8d787d',
      link: '#1ea0f1',
      verifiedIcon: '#1ea0f1',
    },
    loading: false,
    limits: {
      loaded: false,
      maxImages: 0,
      usedImages: 0,
      leftImages: 0,
    },

    textColor: null,

    //  allows small tweaks
    logEnabled: true,

    // background related

    backgroundDialg: false,
    myBackgrounds: [],

    defaultBackgrounds: [
      'background-image: linear-gradient(150deg, rgb(95, 108, 138), rgb(48, 59, 94), rgb(14, 18, 38))',
      'background-image: linear-gradient(150deg, rgb(0, 176, 158), rgb(19, 77, 93), rgb(16, 23, 31))',
      'background-image:linear-gradient(310deg,rgb(214, 233, 255),rgb(214, 229, 255),rgb(209, 214, 255),rgb(221, 209, 255),rgb(243, 209, 255),rgb(255, 204, 245),rgb(255, 204, 223),rgb(255, 200, 199),rgb(255, 216, 199),rgb(255, 221, 199))',
      'background-image:linear-gradient(150deg, rgb(255, 242, 158), rgb(255, 239, 153), rgb(255, 231, 140), rgb(255, 217, 121), rgb(255, 197, 98), rgb(255, 171, 75), rgb(255, 143, 52), rgb(255, 115, 33), rgb(255, 95, 20), rgb(255, 87, 15));',
      'background-image:linear-gradient(330deg, rgb(255, 25, 125), rgb(45, 13, 255), rgb(0, 255, 179))',

    ],
    activeBackground: 'background-image: linear-gradient(150deg, rgb(95, 108, 138), rgb(48, 59, 94), rgb(14, 18, 38));',
    myBackground: null,

    // fonts
    activeFont: 'font-default',
    fontsDialg: false,
    fonts: [
      // register only class names
      'font-default',
      'font-sans-serif-pro',
      'font-open-sans',
      'font-coming-soon',
      'font-poppins',
      'font-helvetica-neue',
    ],
  },
  getters: {},

  mutations,

  actions,
}
