import countriesFull from '@/@fake-db/data/other/countriesFull'
import Vue from 'vue'

// helper function will be used later
Vue.mixin({
  methods: {
    __serverImage: image => process.env.VUE_APP_IMAGE_URL + image,
    __diskImage: image => process.env.VUE_APP_DISK_IMAGE_URL + image,
    __formatDateTime: dateTime => new Date(dateTime).toLocaleString(),
    __countryName: code => {
      if (code === null) return null

      return countriesFull.find(item => item.code === code).name
    },
  },
})
