import axiosClient from '@/plugins/axios'

export default {
  getTweet(payload) {
    return axiosClient.get(`social/twitter/getTweet?tweet_id=${payload}`)
  },

  getBackgrounds() {
    return axiosClient.get('social/twitter/getBackgrounds')
  },

  storeBackground(payload) {
    return axiosClient.post('social/twitter/storeBackground', payload)
  },

  deleteBackground(payload) {
    return axiosClient.delete(`social/twitter/deleteBackground/${payload}`)
  },

  fetchUsageLimits() {
    return axiosClient.get('/auth/getLimits')
  },
  logLimit() {
    return axiosClient.post('/auth/logLimit', {
      limit_id: 'twitter_used_images',
    })
  },
}
