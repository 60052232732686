import apiClient from '@/services'

export default {
  loadingToggle({ state }) {
    state.loading = !state.loading
  },
  setLog({ state }, value) {
    state.logEnabled = value
  },

  logLimit({ state }) {
    state.loading = true

    apiClient
      .logLimit()
      .then(response => {
        state.limits.usedImages = response.data.twitter_used_images
        state.limits.leftImages = state.limits.maxImages - response.data.twitter_used_images
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.loading = false
      })
  },

  async fetchUsageLimits({ commit, state }) {
    if (!state.limits.loaded) {
      try {
        state.loading = true

        const response = await apiClient.fetchUsageLimits()
        commit('SET_LIMITS', {
          maxImages: response.data.limits.twitter_max_images,
          usedImages: response.data.usage.twitter_used_images,
          leftImages: response.data.limits.twitter_max_images - response.data.usage.twitter_used_images,
          loaded: true,
          resetDays: response.data.limits.resetDays,
        })
        state.loading = false
      } catch (error) {
        console.log(error)
      }
    }
  },

  async getBackgrounds({ commit, state }) {
    state.loading = true

    await apiClient
      .getBackgrounds()
      .then(res => {
        commit('SET_MY_BACKGROUNDS', res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.loading = false
      })
  },

  backgroundDialgToggle({ state }) {
    state.backgroundDialg = !state.backgroundDialg
  },

  deleteBackground({ state }, id) {
    state.loading = true
    apiClient
      .deleteBackground(id)
      .then(() => {
        state.myBackgrounds = state.myBackgrounds.filter(el => el.id !== id)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        state.loading = false
      })
  },

  uploadBackground({ state, dispatch }, $event) {
    const input = $event.target

    const file = input.files
    if (file && file[0]) {
      state.loading = true
      const data = new FormData()
      data.append('background', file[0])

      apiClient
        .storeBackground(data)
        .then(res => {
          // log activity
          dispatch('tracking/logActivity', { activityName: 'BackgroundUpload' }, { root: true })
          const background = res.data.data
          background.path = process.env.VUE_APP_IMAGE_URL + background.background_path

          background.background_path = process.env.VUE_APP_IMAGE_URL + background.background_path

          const { myBackgrounds } = state
          myBackgrounds.push(background)
          state.myBackgrounds = myBackgrounds
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          state.loading = false
        })
    }
  },

  applyDefaultBackground({ state }, style) {
    state.myBackground = null
    state.activeBackground = style
    state.colorScheme.backgroundImage = style
  },

  applyMyBackground({ state }, background) {
    state.myBackground = background
    state.activeBackground = `background-image:url(${background.background_path});background-size:cover`
    state.colorScheme.backgroundImage = `background-image:url(${background.background_path});background-size:cover`
  },

  // fonts

  fontsDialogToggle({ state }) {
    state.fontsDialg = !state.fontsDialg
  },
  setActiveFont({ state }, payload) {
    state.activeFont = payload
  },

  setTextColor({ state }, payload) {
    state.textColor = payload
  },

  setColorScheme({ state }, payload) {
    if (payload.key === 'backgroundColor') {
      state.colorScheme.backgroundImage = null
      state.myBackground = null
    }
    state.colorScheme[payload.key] = payload.val
  },

}
