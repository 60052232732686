// eslint-disable-next-line object-curly-newline
import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
]
